import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultTemplate from "../templates/defaultTemplate.js";
export const trackProps = {
  title: `Customer Data`,
  type: `Template`,
  consumption_method: 'Download'
};
export const _frontmatter = {
  "title": "Customer Data Template Download",
  "slug": "tp-download",
  "resource_download_url": "https://docs.google.com/spreadsheets/d/1BA_MyTnAUWv7a66tuWzoZOSmWHqjJZHNtzDMM9aEpWg",
  "resource_name": "Customer Data Template",
  "my_email": "m@michaelsheedy.com",
  "my_email_link": "mailto:m@michaelsheedy.com?subject=Customer Data Template&body=Hi Michael,%0D%0A%0D%0A",
  "url_to_this_page": "https://michaelsheedy.com/customer-data-template",
  "email_url_this_page": "mailto:?subject=Checkout this great template&body=Hey,%0D%0A%0D%0AI Found this Customer Data Template and thought you might like it.%0D%0A%0D%0A"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  trackProps,
  _frontmatter
};
const MDXLayout = DefaultTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Thanks for the dance`}</h1>
    <p>{`I look forward to being in your inbox.`}</p>
    <hr></hr>
    <p>
  <em>You may be interested in my <Link to="/customer-data-template" mdxType="Link">Customer Data Template</Link>. It shows you how to structure a plan that leads with business objectives, clarifies what data must be collected to meet the objectives, and guides the implementation process.</em>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      